import { Box, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Hero from "../utils/hero";
import Page from "../utils/page";
import Section from "../utils/section";
import theme from "../utils/theme";
import useConfig from "../utils/useConfig";

// markup
const ChiSiamoPage = () => {
  const siteMetadata = useConfig();
  const schema = [
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Linda Mettifogo",
      jobTitle: "Web & Horeca sales",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Alessio Balenario",
      jobTitle: "Responsabile produzione",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Omar Marcolongo",
      jobTitle: "Addetto stampa",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
  ];

  return (
    <Page
      title="Horeca per bar, hotel e ristoranti"
      description="Mettifogo Srl ditta specializzata nella produzione e commercializzazione articoli personalizzati per la ristorazione, quali salviette umidificate, salviette e gel igienizzanti monodose, bustine di zucchero, buste portaposate, tovagliette, sottofritti, menu."
      type="AboutPage"
      schema={schema}
      image="/chi-siamo.jpg"
    >
      <Hero
        title="Da 3 generazioni al servizio dei nostri clienti"
        description="Cerchiamo sempre di trovare nuove idee, nuovi obiettivi per essere un passo avanti,  tenendo presente la tradizione ma con una visione moderna e una conoscenza delle tendenze del momento."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../images/backgrounds/fragranza-menta.jpg"
            alt="Fragranza menta"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../images/team/mettifogo-tradizione-da-3-generazioni.jpg"
            alt="Da 3 generazioni Mettifogo al servizio dei clienti"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Confezioni salvafreschezza"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                Chi siamo
              </Typography>
              <Typography>
                Mettifogo, nata come azienda di distribuzione di prodotti
                dolciari si trasforma verso la fine degli anni '80, aggiungendo
                un importante settore, quello della produzione di articoli per
                il mondo horeca, fino ad arrivare al 1998 dove ha inaugurato la
                linea di <strong>salviette umide monouso</strong> con diversi
                tipologie di gusti come salviette <strong>limone</strong>,
                salviette <strong>lime e cannella</strong> e salviette{" "}
                <strong>mojito</strong>.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Il nostro team
            </Typography>
            <Typography>
              Lavoriamo insieme con una procedura pianificata garantendo ai
              nostri clienti il miglior servizio e valorizzando ogni lavoro.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/linda-mettifogo-vendita-horeca-web.jpg"
                alt="Linda Mettifogo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Linda Mettifogo
            </Typography>
            <Typography>Web & Horeca sales</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/alessio-balenario-responsabile-produzione.jpg"
                alt="Alessio Balenario"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Alessio Balenario
            </Typography>
            <Typography>Responsabile produzione</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/omar-macolongo-produzione.jpg"
                alt="Omar Marcolongo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Omar Marcolongo
            </Typography>
            <Typography>Addetto stampa</Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid spacing={6} container justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/spedizione-rapida-horeca.jpg"
                alt="Spedizione rapida in tutta Europa"
                layout="fullWidth"
              />
              <Typography variant="h3" component="h3">
                Spedizione rapida in tutta Europa
              </Typography>
              <Typography>
                Attraverso l'organizzazione dei nostri mezzi di trasporto ed
                insieme all'ausilio dei principali vettori internazionali
                riusciamo a raggiungere tutta la{" "}
                <strong>penisola italiana</strong> e l'
                <strong>intera Europa</strong>.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/made-in-italy.jpg"
                alt="Produzione Made in Italy"
                layout="fullWidth"
              />

              <Typography variant="h3" component="h3">
                Produzione Made in Italy
              </Typography>
              <Typography>
                Le nostre salviette umidificate monouso sono totalmente Made in
                Italy. Tutti i prodotti scelti hanno origine italiana e sono di{" "}
                <strong>prima qualità</strong>, per poter mantenere lo{" "}
                <strong>standard qualitativo</strong> del prodotto negli anni.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};

export default ChiSiamoPage;
